import React from 'react';

const LocalHealthPanoramaLogoIcon = () => (
  <svg
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3668 7.8254L22.8301 13.8837C22.3862 13.6312 21.8788 13.3788 21.3081 13.2526L23.8448 7.19434L25.3668 7.8254Z"
      fill="#E9ECEF"
    />
    <path
      d="M31.328 13.7573L25.2399 16.2816C25.4936 16.7233 25.7473 17.2282 25.8741 17.7962L31.9621 15.2719L31.328 13.7573Z"
      fill="#E9ECEF"
    />
    <path
      d="M7.86366 13.7573L7.22949 15.2719L13.3175 17.7962C13.4444 17.2913 13.698 16.7864 13.9517 16.2816L7.86366 13.7573Z"
      fill="#E9ECEF"
    />
    <path
      d="M7.86366 25.2429L13.9517 22.7187C13.698 22.2769 13.4444 21.7721 13.3175 21.2041L7.22949 23.7284L7.86366 25.2429Z"
      fill="#E9ECEF"
    />
    <path
      d="M23.8448 31.806L25.3668 31.175L22.8301 25.1167C22.3862 25.3691 21.8788 25.6216 21.3081 25.7478L23.8448 31.806Z"
      fill="#E9ECEF"
    />
    <path
      d="M31.328 25.2429L31.9621 23.7284L25.8741 21.2041C25.7473 21.709 25.4936 22.2138 25.2399 22.7187L31.328 25.2429Z"
      fill="#E9ECEF"
    />
    <path
      d="M15.3469 7.19434L13.8249 7.8254L16.3616 13.8837C16.8055 13.6312 17.3128 13.3788 17.8836 13.2526L15.3469 7.19434Z"
      fill="#E9ECEF"
    />
    <path
      d="M15.3469 31.806L17.8836 25.7478C17.3762 25.6216 16.8689 25.3691 16.3616 25.1167L13.8249 31.175L15.3469 31.806Z"
      fill="#E9ECEF"
    />
    <path
      d="M18.7714 13C18.7714 13 18.7714 13.0631 18.7714 13C19.0251 13 19.3422 13 19.5958 13C19.8495 13 20.1666 13 20.4203 13.0631V13C20.4203 8.20391 22.6399 3.91265 26.0644 1.07284C25.4302 0.883524 24.8595 0.694203 24.2253 0.504883C22.1325 2.46119 20.5471 4.92236 19.5958 7.76216C18.708 4.98547 17.0592 2.46119 14.9664 0.504883C14.3322 0.631096 13.6981 0.820417 13.1273 1.07284C16.5518 3.91265 18.7714 8.20391 18.7714 13Z"
      fill="#E9ECEF"
    />
    <path
      d="M23.6545 14.3252C23.5911 14.3252 23.5911 14.3252 23.6545 14.3252C24.035 14.7039 24.4155 15.0825 24.796 15.5243C24.796 15.5243 24.796 15.5243 24.8594 15.4611C28.2839 12.0534 32.85 10.6019 37.2891 10.9806C37.0355 10.4126 36.7184 9.84464 36.3379 9.27668C33.4841 9.21357 30.5669 9.78154 27.9034 11.1699C29.2352 8.58251 29.8694 5.67959 29.8059 2.77668C29.2352 2.46115 28.6644 2.14561 28.0937 1.83008C28.5376 6.37377 27.0156 10.9175 23.6545 14.3252Z"
      fill="#E9ECEF"
    />
    <path
      d="M24.796 23.539C24.796 23.4759 24.7326 23.4759 24.796 23.539C24.4155 23.9177 24.035 24.2963 23.5911 24.675C23.5911 24.675 23.5911 24.675 23.6545 24.7381C27.0791 28.1458 28.5376 32.6895 28.1571 37.107C28.7279 36.8546 29.2986 36.539 29.8694 36.1604C29.9328 33.3206 29.3621 30.4177 27.9669 27.7672C30.567 29.0924 33.4842 29.7235 36.4013 29.6604C36.7184 29.0924 37.0355 28.5245 37.3526 27.9565C32.7866 28.3983 28.2206 26.8837 24.796 23.539Z"
      fill="#E9ECEF"
    />
    <path
      d="M15.5372 24.6746C15.5372 24.6746 15.6006 24.6115 15.5372 24.6746C15.1567 24.296 14.7762 23.9173 14.3957 23.4756C14.3957 23.4756 14.3957 23.4756 14.3322 23.5387C10.9077 26.9465 6.34171 28.3979 1.90253 28.0193C2.21961 28.5241 2.5367 29.0921 2.85378 29.6601C5.70754 29.7232 8.62472 29.1552 11.2882 27.7668C9.95648 30.3542 9.32231 33.2571 9.38573 36.1601C9.95648 36.4756 10.5272 36.7911 11.098 37.1067C10.6541 32.6261 12.1761 28.0824 15.5372 24.6746Z"
      fill="#E9ECEF"
    />
    <path
      d="M31.328 19.4999C34.1183 18.6164 36.655 16.9756 38.6209 14.8931C38.4941 14.262 38.3038 13.6309 38.0502 13.063C35.1964 16.4708 30.884 18.6795 26.0644 18.6795H26.0009C26.0644 18.9319 26.0644 19.2475 26.0644 19.4999C26.0644 19.7523 26.0644 20.0678 26.0009 20.3203H26.0644C30.884 20.3203 35.1964 22.529 38.0502 25.9368C38.2404 25.3057 38.4307 24.7377 38.6209 24.1067C36.655 21.961 34.1817 20.3834 31.328 19.4999Z"
      fill="#E9ECEF"
    />
    <path
      d="M20.4203 26.0001C20.4203 26.0001 20.4203 25.937 20.4203 26.0001C20.1666 26.0001 19.8495 26.0001 19.5958 26.0001C19.3422 26.0001 19.0251 26.0001 18.7714 25.937V26.0001C18.7714 30.7962 16.5518 35.0875 13.1273 37.9273C13.7615 38.1166 14.3322 38.3059 14.9664 38.4953C17.0592 36.539 18.6446 34.0778 19.5958 31.238C20.4837 34.0147 22.1325 36.539 24.2253 38.4953C24.8595 38.3691 25.4936 38.1797 26.0644 37.9273C22.5764 35.0875 20.4203 30.7962 20.4203 26.0001Z"
      fill="#E9ECEF"
    />
    <path
      d="M13.0639 20.3203C13.0639 20.3203 13.1273 20.3203 13.0639 20.3203C13.0639 20.0678 13.0639 19.7523 13.0639 19.4999C13.0639 19.2475 13.0639 18.9319 13.1273 18.6795H13.0639C8.24419 18.6795 3.93184 16.4708 1.07808 13.063C0.887826 13.6941 0.697575 14.262 0.507324 14.8931C2.47325 16.9756 4.94651 18.5533 7.80027 19.4999C5.00993 20.3834 2.47325 22.0242 0.507324 24.1067C0.634158 24.7377 0.824409 25.3688 1.07808 25.9368C3.93184 22.4659 8.24419 20.3203 13.0639 20.3203Z"
      fill="#E9ECEF"
    />
    <path
      d="M14.3956 15.461C14.3956 15.461 14.3956 15.5241 14.3956 15.461C14.7761 15.0824 15.1566 14.7037 15.6005 14.3251C15.6005 14.3251 15.6005 14.3251 15.5371 14.262C12.1126 10.8542 10.654 6.31054 11.0345 1.89307C10.4638 2.14549 9.893 2.46103 9.32225 2.83967C9.25883 5.67947 9.82959 8.58239 11.2248 11.2329C8.62466 9.90763 5.70748 9.27656 2.7903 9.33967C2.47322 9.90763 2.15614 10.4756 1.83905 11.0436C6.40507 10.6018 10.9711 12.0533 14.3956 15.461Z"
      fill="#E9ECEF"
    />
    <path
      d="M19.5958 0C18.7714 0 18.1372 0.631068 18.1372 1.45146C18.1372 2.27184 18.7714 2.90291 19.5958 2.90291C20.4202 2.90291 21.0544 2.27184 21.0544 1.45146C21.0544 0.631068 20.4202 0 19.5958 0Z"
      fill="#2B7B6F"
    />
    <path
      d="M5.70754 5.67939C5.13679 6.24735 5.13679 7.19395 5.70754 7.76191C6.2783 8.32987 7.22955 8.32987 7.8003 7.76191C8.37106 7.19395 8.37106 6.24735 7.8003 5.67939C7.22955 5.11143 6.34171 5.11143 5.70754 5.67939Z"
      fill="#2B7B6F"
    />
    <path
      d="M0 19.4998C0 20.3202 0.634169 20.9513 1.45859 20.9513C2.28301 20.9513 2.91718 20.3202 2.91718 19.4998C2.91718 18.6794 2.28301 18.0483 1.45859 18.0483C0.634169 18.0483 0 18.6794 0 19.4998Z"
      fill="#2B7B6F"
    />
    <path
      d="M5.70754 33.2575C6.2783 33.8255 7.22955 33.8255 7.8003 33.2575C8.37106 32.6896 8.37106 31.743 7.8003 31.175C7.22955 30.607 6.2783 30.607 5.70754 31.175C5.13679 31.8061 5.13679 32.6896 5.70754 33.2575Z"
      fill="#2B7B6F"
    />
    <path
      d="M19.5958 39.0001C20.4202 39.0001 21.0544 38.369 21.0544 37.5486C21.0544 36.7282 20.4202 36.0972 19.5958 36.0972C18.7714 36.0972 18.1372 36.7282 18.1372 37.5486C18.1372 38.369 18.7714 39.0001 19.5958 39.0001Z"
      fill="#2B7B6F"
    />
    <path
      d="M33.4207 33.2575C33.9914 32.6896 33.9914 31.743 33.4207 31.175C32.8499 30.607 31.8987 30.607 31.3279 31.175C30.7572 31.743 30.7572 32.6896 31.3279 33.2575C31.9621 33.8255 32.8499 33.8255 33.4207 33.2575Z"
      fill="#2B7B6F"
    />
    <path
      d="M39.1917 19.4998C39.1917 18.6794 38.5575 18.0483 37.7331 18.0483C36.9087 18.0483 36.2745 18.6794 36.2745 19.4998C36.2745 20.3202 36.9087 20.9513 37.7331 20.9513C38.5575 20.9513 39.1917 20.3202 39.1917 19.4998Z"
      fill="#2B7B6F"
    />
    <path
      d="M33.4207 5.67939C32.8499 5.11143 31.8987 5.11143 31.3279 5.67939C30.7572 6.24735 30.7572 7.19395 31.3279 7.76191C31.8987 8.32987 32.8499 8.32987 33.4207 7.76191C33.9914 7.19395 33.9914 6.31046 33.4207 5.67939Z"
      fill="#2B7B6F"
    />
  </svg>
);

export default LocalHealthPanoramaLogoIcon;
