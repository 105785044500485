import React from 'react';
import setHighlightColor from '../../utils/setHighlightColor';

type Props = {
  secondary?: boolean,
};

const LogoSocialMedia = ({ secondary }: Props) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8995 4.59625L13.4095 8.15465C13.1488 8.00638 12.8508 7.85811 12.5156 7.78398L14.0055 4.22559L14.8995 4.59625Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M18.4009 8.08032L14.825 9.56299C14.974 9.82245 15.123 10.119 15.1975 10.4526L18.7734 8.96992L18.4009 8.08032Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M4.6188 8.08032L4.24631 8.96992L7.82219 10.4526C7.89669 10.1561 8.04568 9.85952 8.19468 9.56299L4.6188 8.08032Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M4.6188 14.8269L8.19468 13.3442C8.04568 13.0847 7.89669 12.7882 7.82219 12.4546L4.24631 13.9373L4.6188 14.8269Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M14.0055 18.6817L14.8995 18.3111L13.4095 14.7527C13.1488 14.901 12.8508 15.0492 12.5156 15.1234L14.0055 18.6817Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M18.4009 14.8269L18.7734 13.9373L15.1975 12.4546C15.123 12.7511 14.974 13.0477 14.825 13.3442L18.4009 14.8269Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M9.01424 4.22559L8.12027 4.59625L9.61022 8.15465C9.87096 8.00638 10.169 7.85811 10.5042 7.78398L9.01424 4.22559Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M9.01424 18.6817L10.5042 15.1234C10.2062 15.0492 9.90821 14.901 9.61022 14.7527L8.12027 18.3111L9.01424 18.6817Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M11.0256 7.63582C11.0256 7.63582 11.0256 7.67289 11.0256 7.63582C11.1746 7.63582 11.3609 7.63582 11.5099 7.63582C11.6589 7.63582 11.8451 7.63582 11.9941 7.67289V7.63582C11.9941 4.81876 13.2978 2.29823 15.3092 0.63023C14.9367 0.519031 14.6015 0.407831 14.229 0.296631C12.9998 1.4457 12.0686 2.89129 11.5099 4.55929C10.9884 2.92836 10.0199 1.4457 8.79069 0.296631C8.41821 0.370764 8.04572 0.481964 7.71048 0.63023C9.72191 2.29823 11.0256 4.81876 11.0256 7.63582Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M13.8938 8.4139C13.8565 8.4139 13.8565 8.4139 13.8938 8.4139C14.1173 8.6363 14.3408 8.8587 14.5643 9.11816C14.5643 9.11816 14.5643 9.11816 14.6015 9.0811C16.6129 7.0795 19.2949 6.22697 21.9023 6.44937C21.7533 6.11577 21.567 5.78217 21.3435 5.44857C19.6673 5.4115 17.9539 5.7451 16.3895 6.56057C17.1717 5.04084 17.5442 3.33577 17.5069 1.63071C17.1717 1.44537 16.8364 1.26004 16.5012 1.07471C16.7619 3.7435 15.868 6.4123 13.8938 8.4139Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M14.5643 13.8261C14.5643 13.7891 14.5271 13.7891 14.5643 13.8261C14.3408 14.0485 14.1173 14.2709 13.8566 14.4933C13.8566 14.4933 13.8566 14.4933 13.8938 14.5304C15.9053 16.532 16.762 19.2008 16.5385 21.7955C16.8737 21.6472 17.209 21.4619 17.5442 21.2395C17.5815 19.5715 17.2462 17.8664 16.4268 16.3096C17.954 17.088 19.6674 17.4587 21.3809 17.4216C21.5671 17.088 21.7533 16.7544 21.9396 16.4208C19.2577 16.6803 16.5758 15.7907 14.5643 13.8261Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M9.12589 14.4928C9.12589 14.4928 9.16314 14.4558 9.12589 14.4928C8.9024 14.2704 8.6789 14.048 8.45541 13.7886C8.45541 13.7886 8.45541 13.7886 8.41816 13.8256C6.40673 15.8272 3.72482 16.6798 1.1174 16.4574C1.30365 16.7539 1.48989 17.0875 1.67613 17.4211C3.35233 17.4582 5.06577 17.1246 6.63022 16.3091C5.848 17.8288 5.47551 19.5339 5.51276 21.239C5.848 21.4243 6.18324 21.6096 6.51847 21.795C6.25773 19.1632 7.1517 16.4944 9.12589 14.4928Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M18.401 11.4534C20.0399 10.9345 21.5299 9.97074 22.6846 8.74754C22.6101 8.37687 22.4984 8.00621 22.3494 7.67261C20.6732 9.6742 18.1403 10.9715 15.3093 10.9715H15.2721C15.3093 11.1198 15.3093 11.3051 15.3093 11.4534C15.3093 11.6017 15.3093 11.787 15.2721 11.9353H15.3093C18.1403 11.9353 20.6732 13.2326 22.3494 15.2342C22.4611 14.8635 22.5729 14.5299 22.6846 14.1593C21.5299 12.899 20.0772 11.9723 18.401 11.4534Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M11.9941 15.2714C11.9941 15.2714 11.9941 15.2344 11.9941 15.2714C11.8451 15.2714 11.6589 15.2714 11.5099 15.2714C11.3609 15.2714 11.1746 15.2714 11.0256 15.2344V15.2714C11.0256 18.0885 9.72191 20.609 7.71048 22.277C8.08297 22.3882 8.41821 22.4994 8.79069 22.6106C10.0199 21.4616 10.9511 20.016 11.5099 18.348C12.0313 19.9789 12.9998 21.4616 14.229 22.6106C14.6015 22.5365 14.974 22.4253 15.3092 22.277C13.2605 20.609 11.9941 18.0885 11.9941 15.2714Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M7.6732 11.9353C7.6732 11.9353 7.71045 11.9353 7.6732 11.9353C7.6732 11.787 7.6732 11.6017 7.6732 11.4534C7.6732 11.3051 7.6732 11.1198 7.71045 10.9715H7.6732C4.8423 10.9715 2.30938 9.6742 0.633182 7.67261C0.521436 8.04327 0.409689 8.37687 0.297943 8.74754C1.45266 9.97074 2.90536 10.8974 4.58155 11.4534C2.94261 11.9723 1.45266 12.9361 0.297943 14.1593C0.372441 14.5299 0.484187 14.9006 0.633182 15.2342C2.30938 13.1955 4.8423 11.9353 7.6732 11.9353Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M8.4554 9.08114C8.4554 9.08114 8.4554 9.11821 8.4554 9.08114C8.67889 8.85874 8.90238 8.63634 9.16313 8.41394C9.16313 8.41394 9.16313 8.41394 9.12588 8.37687C7.11444 6.37528 6.25772 3.70648 6.48121 1.11182C6.14597 1.26008 5.81073 1.44542 5.4755 1.66782C5.43825 3.33581 5.77349 5.04088 6.59296 6.59768C5.06576 5.81928 3.35232 5.44861 1.63887 5.48568C1.45263 5.81928 1.26638 6.15288 1.08014 6.48648C3.76205 6.22701 6.44396 7.07954 8.4554 9.08114Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M11.5099 0C11.0257 0 10.6532 0.370666 10.6532 0.852532C10.6532 1.3344 11.0257 1.70506 11.5099 1.70506C11.9941 1.70506 12.3666 1.3344 12.3666 0.852532C12.3666 0.370666 11.9941 0 11.5099 0Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M3.35241 3.33589C3.01717 3.66949 3.01717 4.22549 3.35241 4.55909C3.68765 4.89269 4.24638 4.89269 4.58162 4.55909C4.91686 4.22549 4.91686 3.66949 4.58162 3.33589C4.24638 3.00229 3.7249 3.00229 3.35241 3.33589Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M3.05176e-05 11.4534C3.05176e-05 11.9352 0.372518 12.3059 0.856753 12.3059C1.34099 12.3059 1.71347 11.9352 1.71347 11.4534C1.71347 10.9715 1.34099 10.6008 0.856753 10.6008C0.372518 10.6008 3.05176e-05 10.9715 3.05176e-05 11.4534Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M3.35241 19.5342C3.68765 19.8678 4.24638 19.8678 4.58162 19.5342C4.91686 19.2006 4.91686 18.6446 4.58162 18.311C4.24638 17.9774 3.68765 17.9774 3.35241 18.311C3.01717 18.6817 3.01717 19.2006 3.35241 19.5342Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M11.5099 22.907C11.9941 22.907 12.3666 22.5363 12.3666 22.0544C12.3666 21.5726 11.9941 21.2019 11.5099 21.2019C11.0257 21.2019 10.6532 21.5726 10.6532 22.0544C10.6532 22.5363 11.0257 22.907 11.5099 22.907Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M19.6301 19.5342C19.9654 19.2006 19.9654 18.6446 19.6301 18.311C19.2949 17.9774 18.7362 17.9774 18.4009 18.311C18.0657 18.6446 18.0657 19.2006 18.4009 19.5342C18.7734 19.8678 19.2949 19.8678 19.6301 19.5342Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M23.0198 11.4534C23.0198 10.9715 22.6474 10.6008 22.1631 10.6008C21.6789 10.6008 21.3064 10.9715 21.3064 11.4534C21.3064 11.9352 21.6789 12.3059 22.1631 12.3059C22.6474 12.3059 23.0198 11.9352 23.0198 11.4534Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
    <path
      d="M19.6301 3.33589C19.2949 3.00229 18.7362 3.00229 18.4009 3.33589C18.0657 3.66949 18.0657 4.22549 18.4009 4.55909C18.7362 4.89269 19.2949 4.89269 19.6301 4.55909C19.9654 4.22549 19.9654 3.70656 19.6301 3.33589Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
  </svg>
);

export default LogoSocialMedia;
