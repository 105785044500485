import React from 'react';
import { theme, textColor } from '../../constants/theme';
import setHighlightColor from '../../utils/setHighlightColor';

type Props = {
  highlightOnHover?: boolean,
};

const DataDownloadsIcon = ({ highlightOnHover }: Props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3162 9.84777C14.4986 9.84777 14.7722 9.84777 14.9545 9.84777C15.1369 9.84777 15.4105 9.84777 15.5928 9.84777C15.5928 6.01799 17.3253 2.64413 20.0609 0.455682C19.605 0.273312 19.149 0.0909412 18.6019 -0.000244141C16.9606 1.54991 15.684 3.55598 14.9545 5.74443C14.225 3.55598 12.9484 1.54991 11.3071 -0.000244141C10.8512 0.0909412 10.3041 0.273312 9.84814 0.455682C12.5837 2.64413 14.3162 6.01799 14.3162 9.84777Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M18.1458 10.942C18.4194 11.2156 18.7841 11.5803 19.0577 11.8539C21.7021 9.20949 25.3495 8.02408 28.9057 8.29764C28.7233 7.84171 28.4498 7.38578 28.1762 6.92986C25.8966 6.83867 23.617 7.38578 21.5197 8.38882C22.6139 6.38274 23.0699 4.10311 23.0699 1.82348C22.6139 1.54992 22.158 1.27636 21.7021 1.09399C22.0668 4.55904 20.8814 8.20645 18.1458 10.942Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M19.0577 18.1458C18.7841 18.4193 18.4194 18.784 18.1458 19.0576C20.7902 21.702 21.9756 25.3494 21.7021 28.9056C22.158 28.7233 22.6139 28.4497 23.0699 28.1761C23.161 25.8965 22.6139 23.6169 21.6109 21.5196C23.7081 22.6138 25.9878 23.0698 28.2674 22.9786C28.541 22.5226 28.8145 22.0667 28.9969 21.6108C25.3495 22.0667 21.7933 20.8813 19.0577 18.1458Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M11.8541 19.0576C11.5806 18.784 11.2158 18.4193 10.9423 18.1458C8.2067 20.8813 4.55928 22.0667 1.09424 21.702C1.27661 22.1579 1.55016 22.6138 1.82372 23.0698C4.10335 23.1609 6.38299 22.6138 8.48025 21.6108C7.38603 23.7081 6.9301 25.9877 7.02129 28.2673C7.47721 28.5409 7.93314 28.8144 8.38907 28.9968C7.93314 25.3494 9.11855 21.7932 11.8541 19.0576Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M24.2554 14.954C26.4438 14.2246 28.4499 12.948 30.0001 11.3066C29.9089 10.8507 29.7265 10.3036 29.5441 9.84766C27.2645 12.5832 23.8906 14.3157 20.152 14.3157C20.152 14.4981 20.152 14.7717 20.152 14.954C20.152 15.1364 20.152 15.41 20.152 15.5923C23.9818 15.5923 27.3557 17.3249 29.5441 20.0604C29.7265 19.6045 29.8177 19.0574 30.0001 18.6014C28.4499 16.9601 26.4438 15.6835 24.2554 14.954Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M15.5928 20.1519C15.5928 20.1519 15.5928 20.0607 15.5928 20.1519C15.4105 20.1519 15.1369 20.1519 14.9545 20.1519C14.7722 20.1519 14.4986 20.1519 14.3162 20.1519C14.3162 23.9816 12.5837 27.3555 9.84814 29.5439C10.3041 29.7263 10.8512 29.8175 11.3071 29.9999C12.9484 28.4497 14.225 26.4436 14.9545 24.2552C15.684 26.4436 16.9606 28.4497 18.6019 29.9999C19.0579 29.9087 19.605 29.7263 20.0609 29.5439C17.3253 27.3555 15.5928 23.8905 15.5928 20.1519Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M9.84801 15.5923C9.84801 15.41 9.84801 15.1364 9.84801 14.954C9.84801 14.7717 9.84801 14.4981 9.84801 14.3157C6.01823 14.3157 2.64437 12.5832 0.455927 9.84766C0.273556 10.3036 0.182371 10.8507 0 11.3066C1.55015 12.948 3.55623 14.2246 5.74468 14.954C3.55623 15.6835 1.55015 16.9601 0 18.6014C0.0911853 19.1486 0.273556 19.6045 0.455927 20.0604C2.64437 17.3249 6.01823 15.5923 9.84801 15.5923Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
    <path
      d="M10.9423 11.8539C11.2158 11.5803 11.5806 11.2156 11.8541 10.942C9.11855 8.20645 7.93314 4.55904 8.29788 1.09399C7.84195 1.27636 7.38603 1.54992 6.9301 1.82348C6.83891 4.10311 7.38603 6.38274 8.48025 8.48001C6.38299 7.38578 4.10335 6.83867 1.82372 6.92986C1.55016 7.38578 1.27661 7.84171 1.09424 8.29764C4.55928 7.9329 8.2067 9.11831 10.9423 11.8539Z"
      fill={setHighlightColor(
        highlightOnHover,
        textColor,
        theme.palette.methods.main
      )}
    />
  </svg>
);
export default DataDownloadsIcon;
