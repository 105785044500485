import React from 'react';
import setHighlightColor from '../../utils/setHighlightColor';

type Props = {
  secondary: boolean,
};

const TwitterIcon = ({ secondary }: Props) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 0C9.22552 0 7.00211 0.674463 5.11095 1.9381C3.21978 3.20174 1.7458 4.99779 0.875391 7.09914C0.00498277 9.20049 -0.222756 11.5128 0.220974 13.7435C0.664704 15.9743 1.75997 18.0234 3.36828 19.6317C4.97658 21.24 7.02569 22.3353 9.25647 22.779C11.4872 23.2228 13.7995 22.995 15.9009 22.1246C18.0022 21.2542 19.7983 19.7802 21.0619 17.8891C22.3255 15.9979 23 13.7745 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0ZM16.43 9.63C16.4353 9.73467 16.4377 9.84033 16.437 9.947C16.4375 11.1982 16.1013 12.4264 15.4636 13.5029C14.8259 14.5794 13.9103 15.4644 12.8127 16.0651C11.7152 16.6657 10.4762 16.9599 9.22578 16.9168C7.97535 16.8737 6.75957 16.4949 5.706 15.82C5.90017 15.8427 6.09551 15.8541 6.29101 15.854C7.39463 15.8558 8.46678 15.4863 9.33501 14.805C8.82377 14.7955 8.32825 14.6266 7.91769 14.3218C7.50713 14.017 7.20202 13.5916 7.04501 13.105C7.41239 13.1755 7.79102 13.1611 8.15201 13.063C7.59794 12.9508 7.09964 12.6506 6.7414 12.2133C6.38316 11.776 6.18697 11.2283 6.18601 10.663C6.18601 10.652 6.18601 10.642 6.18601 10.632C6.52612 10.8215 6.90682 10.9265 7.296 10.938C6.77685 10.5916 6.4093 10.0603 6.26828 9.45237C6.12725 8.84441 6.22335 8.20556 6.53701 7.666C7.1526 8.4243 7.92094 9.04451 8.79204 9.48626C9.66315 9.92802 10.6175 10.1814 11.593 10.23C11.4692 9.70409 11.5226 9.15202 11.7447 8.65952C11.9668 8.16701 12.3453 7.76162 12.8215 7.50628C13.2976 7.25094 13.8447 7.15994 14.3779 7.24741C14.911 7.33487 15.4004 7.59591 15.77 7.99C16.3188 7.88182 16.8451 7.68059 17.326 7.395C17.1429 7.96271 16.7598 8.44458 16.248 8.751C16.7336 8.69315 17.2079 8.56305 17.655 8.365C17.325 8.85606 16.9102 9.28442 16.43 9.63Z"
      fill={setHighlightColor(secondary, '#343A40', '#CED4DA')}
    />
  </svg>
);

export default TwitterIcon;
