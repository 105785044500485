import React from 'react';

const DownloadsIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5577 11.7657V15.3506C16.5577 15.4224 16.5471 15.4885 16.5316 15.5499C16.4583 15.9021 16.1406 16.1666 15.7651 16.1666H0.787696C0.386109 16.1666 0.0529626 15.8654 0.0105894 15.4829C5.20478e-08 15.4367 0 15.396 0 15.345V11.7601C0 11.337 0.354333 10.9951 0.781986 10.9951C0.995395 10.9951 1.19417 11.082 1.33426 11.2198C1.47518 11.3577 1.56397 11.5513 1.56397 11.7601V14.6311H14.9987V11.7601C14.9987 11.337 15.353 10.9951 15.7807 10.9951C15.9941 10.9951 16.1929 11.082 16.333 11.2198C16.469 11.3625 16.5578 11.5561 16.5578 11.7657L16.5577 11.7657Z"
      fill="white"
    />
    <path
      d="M12.7886 8.17477L8.94626 11.9335C8.94137 11.9439 8.93078 11.9486 8.92508 11.9542C8.78416 12.0921 8.60169 12.1789 8.41923 12.2044C8.40375 12.2044 8.38828 12.2092 8.37198 12.2092C8.34103 12.214 8.30926 12.214 8.27831 12.214L8.18952 12.2092C8.17404 12.2092 8.15857 12.2044 8.14228 12.2044C7.95492 12.1789 7.77735 12.0921 7.63642 11.9542C7.63154 11.9494 7.62095 11.9391 7.61524 11.9335L3.77287 8.17477C3.59529 8.00106 3.50732 7.77156 3.50732 7.54207C3.50732 7.31258 3.59611 7.08309 3.77287 6.90937C4.1272 6.56275 4.70637 6.56275 5.06559 6.90937L7.37485 9.16836L7.3773 0.89246C7.3773 0.403208 7.78948 0 8.28962 0C8.53968 0 8.7694 0.101995 8.93639 0.259762C9.10338 0.423114 9.20194 0.642245 9.20194 0.89246V9.16918L11.5112 6.91019C11.8655 6.56357 12.4447 6.56357 12.8039 6.91019C13.1428 7.2624 13.1428 7.82815 12.7884 8.17477H12.7886Z"
      fill="white"
    />
  </svg>
);
export default DownloadsIcon;
