import React from 'react';

function LinkGo() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3125 11.375C1.3125 11.7231 1.45078 12.0569 1.69692 12.3031C1.94306 12.5492 2.2769 12.6875 2.625 12.6875H9.625C9.9731 12.6875 10.3069 12.5492 10.5531 12.3031C10.7992 12.0569 10.9375 11.7231 10.9375 11.375V7.875C10.9375 7.75897 10.8914 7.64769 10.8094 7.56564C10.7273 7.48359 10.616 7.4375 10.5 7.4375C10.384 7.4375 10.2727 7.48359 10.1906 7.56564C10.1086 7.64769 10.0625 7.75897 10.0625 7.875V11.375C10.0625 11.491 10.0164 11.6023 9.93436 11.6844C9.85231 11.7664 9.74103 11.8125 9.625 11.8125H2.625C2.50897 11.8125 2.39769 11.7664 2.31564 11.6844C2.23359 11.6023 2.1875 11.491 2.1875 11.375V4.375C2.1875 4.25897 2.23359 4.14769 2.31564 4.06564C2.39769 3.98359 2.50897 3.9375 2.625 3.9375H6.125C6.24103 3.9375 6.35231 3.89141 6.43436 3.80936C6.51641 3.72731 6.5625 3.61603 6.5625 3.5C6.5625 3.38397 6.51641 3.27269 6.43436 3.19064C6.35231 3.10859 6.24103 3.0625 6.125 3.0625H2.625C2.2769 3.0625 1.94306 3.20078 1.69692 3.44692C1.45078 3.69306 1.3125 4.0269 1.3125 4.375V11.375ZM7.4375 1.75C7.4375 1.63397 7.48359 1.52269 7.56564 1.44064C7.64769 1.35859 7.75897 1.3125 7.875 1.3125H12.25C12.366 1.3125 12.4773 1.35859 12.5594 1.44064C12.6414 1.52269 12.6875 1.63397 12.6875 1.75V6.125C12.6875 6.24103 12.6414 6.35231 12.5594 6.43436C12.4773 6.51641 12.366 6.5625 12.25 6.5625C12.134 6.5625 12.0227 6.51641 11.9406 6.43436C11.8586 6.35231 11.8125 6.24103 11.8125 6.125V2.1875H7.875C7.75897 2.1875 7.64769 2.14141 7.56564 2.05936C7.48359 1.97731 7.4375 1.86603 7.4375 1.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5598 1.44024C12.6006 1.48088 12.6329 1.52916 12.6549 1.58231C12.677 1.63546 12.6883 1.69244 12.6883 1.74999C12.6883 1.80754 12.677 1.86452 12.6549 1.91767C12.6329 1.97082 12.6006 2.0191 12.5598 2.05974L5.55981 9.05974C5.51914 9.10042 5.47085 9.13268 5.4177 9.1547C5.36455 9.17671 5.30759 9.18804 5.25006 9.18804C5.19254 9.18804 5.13558 9.17671 5.08243 9.1547C5.02928 9.13268 4.98099 9.10042 4.94031 9.05974C4.89964 9.01906 4.86737 8.97077 4.84536 8.91762C4.82334 8.86448 4.81201 8.80752 4.81201 8.74999C4.81201 8.69246 4.82334 8.6355 4.84536 8.58235C4.86737 8.52921 4.89964 8.48092 4.94031 8.44024L11.9403 1.44024C11.981 1.3995 12.0292 1.36717 12.0824 1.34512C12.1355 1.32306 12.1925 1.31171 12.2501 1.31171C12.3076 1.31171 12.3646 1.32306 12.4177 1.34512C12.4709 1.36717 12.5192 1.3995 12.5598 1.44024Z"
        fill="white"
      />
    </svg>
  );
}

export default LinkGo;
