import React from 'react';

const LogoCopyIcon = () => (
  <svg
    width="82"
    height="25"
    viewBox="0 0 82 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.128754 0.194336H5.83628C13.4463 0.194336 17.0611 4.99045 17.0611 12.4371C17.0611 19.8837 13.4463 24.8691 5.8997 24.8691H0.128754V0.194336ZM5.64603 23.3545C12.3048 23.3545 15.222 18.8108 15.222 12.4371C15.222 6.00016 12.3048 1.64579 5.64603 1.64579H1.84101V23.3545H5.64603Z"
      fill="black"
    />
    <path
      d="M29.4908 0.194336H31.2665L39.9546 24.806H38.0521L33.1056 10.2914C32.0909 7.38851 31.203 4.80113 30.3786 1.77201H30.2518C29.364 4.80113 28.5395 7.38851 27.5249 10.2914L22.5149 24.806H20.7393L29.4908 0.194336Z"
      fill="black"
    />
    <path
      d="M50.5453 1.64567H41.9206V0.131104H60.8822V1.64567H52.2575V24.8059H50.5453V1.64567Z"
      fill="black"
    />
    <path
      d="M71.5363 0.194336H73.3119L82.0001 24.8691H80.161L75.2144 10.3545C74.1998 7.45162 73.3119 4.86424 72.4875 1.83511H72.3607C71.4728 4.86424 70.6484 7.45162 69.6338 10.3545L64.6238 24.8691H62.8481L71.5363 0.194336Z"
      fill="black"
    />
  </svg>
);
export default LogoCopyIcon;
