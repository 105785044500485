import React from 'react';

const ArrowLeftIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12224 4.0653C5.16298 4.10594 5.19531 4.15422 5.21736 4.20737C5.23942 4.26052 5.25077 4.3175 5.25077 4.37505C5.25077 4.4326 5.23942 4.48958 5.21736 4.54273C5.19531 4.59588 5.16298 4.64416 5.12224 4.6848L2.80611 7.00005L5.12224 9.3153C5.16292 9.35598 5.19518 9.40427 5.2172 9.45741C5.23921 9.51056 5.25054 9.56752 5.25054 9.62505C5.25054 9.68257 5.23921 9.73954 5.2172 9.79268C5.19518 9.84583 5.16292 9.89412 5.12224 9.9348C5.08156 9.97548 5.03327 10.0077 4.98012 10.0298C4.92698 10.0518 4.87001 10.0631 4.81249 10.0631C4.75496 10.0631 4.698 10.0518 4.64485 10.0298C4.59171 10.0077 4.54342 9.97548 4.50274 9.9348L1.87774 7.3098C1.837 7.26916 1.80467 7.22088 1.78262 7.16773C1.76056 7.11458 1.74921 7.0576 1.74921 7.00005C1.74921 6.9425 1.76056 6.88552 1.78262 6.83237C1.80467 6.77922 1.837 6.73094 1.87774 6.6903L4.50274 4.0653C4.54338 4.02456 4.59166 3.99223 4.64481 3.97018C4.69796 3.94812 4.75494 3.93677 4.81249 3.93677C4.87004 3.93677 4.92702 3.94812 4.98017 3.97018C5.03332 3.99223 5.0816 4.02456 5.12224 4.0653V4.0653Z"
      fill="#495057"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.1875 7C2.1875 6.88397 2.23359 6.77269 2.31564 6.69064C2.39769 6.60859 2.50897 6.5625 2.625 6.5625H11.8125C11.9285 6.5625 12.0398 6.60859 12.1219 6.69064C12.2039 6.77269 12.25 6.88397 12.25 7C12.25 7.11603 12.2039 7.22731 12.1219 7.30936C12.0398 7.39141 11.9285 7.4375 11.8125 7.4375H2.625C2.50897 7.4375 2.39769 7.39141 2.31564 7.30936C2.23359 7.22731 2.1875 7.11603 2.1875 7Z"
      fill="black"
    />
  </svg>
);
export default ArrowLeftIcon;
