import React from 'react';

const AboutLogoIcon = () => (
  <svg
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3668 7.8254L22.8301 13.8837C22.3862 13.6312 21.8788 13.3788 21.3081 13.2526L23.8448 7.19434L25.3668 7.8254Z"
      fill="#FFC20E"
    />
    <path
      d="M31.328 13.7578L25.2399 16.2821C25.4936 16.7238 25.7473 17.2287 25.8741 17.7966L31.9621 15.2724L31.328 13.7578Z"
      fill="#FFC20E"
    />
    <path
      d="M7.86366 13.7578L7.22949 15.2724L13.3175 17.7966C13.4444 17.2918 13.698 16.7869 13.9517 16.2821L7.86366 13.7578Z"
      fill="#FFC20E"
    />
    <path
      d="M7.86366 25.2429L13.9517 22.7187C13.698 22.2769 13.4444 21.7721 13.3175 21.2041L7.22949 23.7284L7.86366 25.2429Z"
      fill="#FFC20E"
    />
    <path
      d="M23.8448 31.8055L25.3668 31.1745L22.8301 25.1162C22.3862 25.3686 21.8788 25.6211 21.3081 25.7473L23.8448 31.8055Z"
      fill="#FFC20E"
    />
    <path
      d="M31.328 25.2429L31.9621 23.7284L25.8741 21.2041C25.7473 21.709 25.4936 22.2138 25.2399 22.7187L31.328 25.2429Z"
      fill="#FFC20E"
    />
    <path
      d="M15.3469 7.19434L13.8249 7.8254L16.3616 13.8837C16.8055 13.6312 17.3128 13.3788 17.8836 13.2526L15.3469 7.19434Z"
      fill="#FFC20E"
    />
    <path
      d="M15.3469 31.8055L17.8836 25.7473C17.3762 25.6211 16.8689 25.3686 16.3616 25.1162L13.8249 31.1745L15.3469 31.8055Z"
      fill="#FFC20E"
    />
    <path
      d="M18.7714 13C18.7714 13 18.7714 13.0631 18.7714 13C19.0251 13 19.3422 13 19.5958 13C19.8495 13 20.1666 13 20.4203 13.0631V13C20.4203 8.20391 22.6399 3.91265 26.0644 1.07284C25.4302 0.883524 24.8595 0.694203 24.2253 0.504883C22.1325 2.46119 20.5471 4.92236 19.5958 7.76216C18.708 4.98547 17.0592 2.46119 14.9664 0.504883C14.3322 0.631096 13.6981 0.820417 13.1273 1.07284C16.5518 3.91265 18.7714 8.20391 18.7714 13Z"
      fill="#FFC20E"
    />
    <path
      d="M23.6545 14.3252C23.5911 14.3252 23.5911 14.3252 23.6545 14.3252C24.035 14.7039 24.4155 15.0825 24.796 15.5243C24.796 15.5243 24.796 15.5243 24.8594 15.4611C28.2839 12.0534 32.85 10.6019 37.2891 10.9806C37.0355 10.4126 36.7184 9.84464 36.3379 9.27668C33.4841 9.21357 30.5669 9.78154 27.9034 11.1699C29.2352 8.58251 29.8694 5.67959 29.8059 2.77668C29.2352 2.46115 28.6644 2.14561 28.0937 1.83008C28.5376 6.37377 27.0156 10.9175 23.6545 14.3252Z"
      fill="#FFC20E"
    />
    <path
      d="M24.796 23.5385C24.796 23.4754 24.7326 23.4754 24.796 23.5385C24.4155 23.9172 24.035 24.2958 23.5911 24.6745C23.5911 24.6745 23.5911 24.6745 23.6545 24.7376C27.0791 28.1453 28.5376 32.689 28.1571 37.1065C28.7279 36.8541 29.2986 36.5385 29.8694 36.1599C29.9328 33.3201 29.3621 30.4172 27.9669 27.7667C30.567 29.0919 33.4842 29.723 36.4013 29.6599C36.7184 29.0919 37.0355 28.524 37.3526 27.956C32.7866 28.3978 28.2206 26.8832 24.796 23.5385Z"
      fill="#FFC20E"
    />
    <path
      d="M15.5372 24.6746C15.5372 24.6746 15.6006 24.6115 15.5372 24.6746C15.1567 24.296 14.7762 23.9173 14.3957 23.4756C14.3957 23.4756 14.3957 23.4756 14.3322 23.5387C10.9077 26.9465 6.34171 28.3979 1.90253 28.0193C2.21961 28.5241 2.5367 29.0921 2.85378 29.6601C5.70754 29.7232 8.62472 29.1552 11.2882 27.7668C9.95648 30.3542 9.32231 33.2571 9.38573 36.1601C9.95648 36.4756 10.5272 36.7911 11.098 37.1067C10.6541 32.6261 12.1761 28.0824 15.5372 24.6746Z"
      fill="#FFC20E"
    />
    <path
      d="M31.328 19.5004C34.1183 18.6169 36.655 16.9761 38.6209 14.8936C38.4941 14.2625 38.3038 13.6314 38.0502 13.0635C35.1964 16.4712 30.884 18.68 26.0644 18.68H26.0009C26.0644 18.9324 26.0644 19.2479 26.0644 19.5004C26.0644 19.7528 26.0644 20.0683 26.0009 20.3208H26.0644C30.884 20.3208 35.1964 22.5295 38.0502 25.9373C38.2404 25.3062 38.4307 24.7382 38.6209 24.1072C36.655 21.9615 34.1817 20.3839 31.328 19.5004Z"
      fill="#FFC20E"
    />
    <path
      d="M20.4203 25.9996C20.4203 25.9996 20.4203 25.9365 20.4203 25.9996C20.1666 25.9996 19.8495 25.9996 19.5958 25.9996C19.3422 25.9996 19.0251 25.9996 18.7714 25.9365V25.9996C18.7714 30.7957 16.5518 35.087 13.1273 37.9268C13.7615 38.1161 14.3322 38.3055 14.9664 38.4948C17.0592 36.5385 18.6446 34.0773 19.5958 31.2375C20.4837 34.0142 22.1325 36.5385 24.2253 38.4948C24.8595 38.3686 25.4936 38.1792 26.0644 37.9268C22.5764 35.087 20.4203 30.7957 20.4203 25.9996Z"
      fill="#FFC20E"
    />
    <path
      d="M13.0639 20.3208C13.0639 20.3208 13.1273 20.3208 13.0639 20.3208C13.0639 20.0683 13.0639 19.7528 13.0639 19.5004C13.0639 19.2479 13.0639 18.9324 13.1273 18.68H13.0639C8.24419 18.68 3.93184 16.4712 1.07808 13.0635C0.887826 13.6945 0.697575 14.2625 0.507324 14.8936C2.47325 16.9761 4.94651 18.5538 7.80027 19.5004C5.00993 20.3839 2.47325 22.0246 0.507324 24.1072C0.634158 24.7382 0.824409 25.3693 1.07808 25.9373C3.93184 22.4664 8.24419 20.3208 13.0639 20.3208Z"
      fill="#FFC20E"
    />
    <path
      d="M14.3956 15.4615C14.3956 15.4615 14.3956 15.5246 14.3956 15.4615C14.7761 15.0829 15.1566 14.7042 15.6005 14.3256C15.6005 14.3256 15.6005 14.3256 15.5371 14.2625C12.1126 10.8547 10.654 6.31103 11.0345 1.89355C10.4638 2.14598 9.893 2.46152 9.32225 2.84016C9.25883 5.67996 9.82959 8.58288 11.2248 11.2334C8.62466 9.90812 5.70748 9.27705 2.7903 9.34016C2.47322 9.90812 2.15614 10.4761 1.83905 11.044C6.40507 10.6023 10.9711 12.0537 14.3956 15.4615Z"
      fill="#FFC20E"
    />
    <path
      d="M19.5958 0C18.7714 0 18.1372 0.631068 18.1372 1.45146C18.1372 2.27184 18.7714 2.90291 19.5958 2.90291C20.4202 2.90291 21.0544 2.27184 21.0544 1.45146C21.0544 0.631068 20.4202 0 19.5958 0Z"
      fill="#FFC20E"
    />
    <path
      d="M5.70754 5.67988C5.13679 6.24784 5.13679 7.19444 5.70754 7.7624C6.2783 8.33036 7.22955 8.33036 7.8003 7.7624C8.37106 7.19444 8.37106 6.24784 7.8003 5.67988C7.22955 5.11192 6.34171 5.11192 5.70754 5.67988Z"
      fill="#FFC20E"
    />
    <path
      d="M0 19.5003C0 20.3207 0.634169 20.9517 1.45859 20.9517C2.28301 20.9517 2.91718 20.3207 2.91718 19.5003C2.91718 18.6799 2.28301 18.0488 1.45859 18.0488C0.634169 18.0488 0 18.6799 0 19.5003Z"
      fill="#FFC20E"
    />
    <path
      d="M5.70754 33.2575C6.2783 33.8255 7.22955 33.8255 7.8003 33.2575C8.37106 32.6896 8.37106 31.743 7.8003 31.175C7.22955 30.607 6.2783 30.607 5.70754 31.175C5.13679 31.8061 5.13679 32.6896 5.70754 33.2575Z"
      fill="#FFC20E"
    />
    <path
      d="M19.5958 38.9996C20.4202 38.9996 21.0544 38.3685 21.0544 37.5481C21.0544 36.7277 20.4202 36.0967 19.5958 36.0967C18.7714 36.0967 18.1372 36.7277 18.1372 37.5481C18.1372 38.3685 18.7714 38.9996 19.5958 38.9996Z"
      fill="#FFC20E"
    />
    <path
      d="M33.4207 33.2575C33.9914 32.6896 33.9914 31.743 33.4207 31.175C32.8499 30.607 31.8987 30.607 31.3279 31.175C30.7572 31.743 30.7572 32.6896 31.3279 33.2575C31.9621 33.8255 32.8499 33.8255 33.4207 33.2575Z"
      fill="#FFC20E"
    />
    <path
      d="M39.1917 19.5003C39.1917 18.6799 38.5575 18.0488 37.7331 18.0488C36.9087 18.0488 36.2745 18.6799 36.2745 19.5003C36.2745 20.3207 36.9087 20.9517 37.7331 20.9517C38.5575 20.9517 39.1917 20.3207 39.1917 19.5003Z"
      fill="#FFC20E"
    />
    <path
      d="M33.4207 5.67988C32.8499 5.11192 31.8987 5.11192 31.3279 5.67988C30.7572 6.24784 30.7572 7.19444 31.3279 7.7624C31.8987 8.33036 32.8499 8.33036 33.4207 7.7624C33.9914 7.19444 33.9914 6.31094 33.4207 5.67988Z"
      fill="#FFC20E"
    />
  </svg>
);
export default AboutLogoIcon;
